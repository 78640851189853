import styled from "styled-components";
export const invertColor = "#ffffff";

/* Palette generated by Palette List - palettelist.com */
export const firstColor = "#233142";
export const secondColor = "#455d7a";
export const thirdColor = "#80999c"; // これは自分で加えた
export const accentColor = "#f95959";
export const backgroundColor = "#e3e3e3";
export const primaryTextColor = "#212121";
export const secondaryTextColor = "#757575";
export const placeholderColor = " #c4c4c4";
export const dividerColor = "#BDBDBD";
export const iconColor = "#000000";
export const warningColor = "#f95959";
export const safeColor = "#007700";

/*
 レイアウトの定数
*/
export const topBarHeight = 72;
export const basePadding = 12;
export const smallPadding = 6;
export const xsFontSize = 12;
export const smallFontSize = 14;
export const mediumFontSize = 16;
export const largeFontSize = 24;
/*
共通利用するコンポーネント
*/
export const Link = styled.a`
  color: ${secondColor};
  text-decoration: none;
  &:hover {
    color: ${thirdColor};
  }
`;

export const ExtraSmallText = styled.span`
  color: ${({ theme }) => (theme.color ? theme.color : secondaryTextColor)};
  font-size: ${xsFontSize}px;
  font-weight: ${({ theme }) =>
    theme.fontWeight ? theme.fontWeight : "normal"};
  display: ${({ theme }) => (theme.display ? theme.display : "inline")};
  line-height: ${xsFontSize * 1.5}px;
`;
export const SmallText = styled.span`
  color: ${({ theme }) => (theme.color ? theme.color : secondaryTextColor)};
  font-size: ${smallFontSize}px;
  font-weight: ${({ theme }) =>
    theme.fontWeight ? theme.fontWeight : "normal"};
  display: ${({ theme }) => (theme.display ? theme.display : "inline")};
  line-height: ${smallFontSize * 1.5}px;
`;

export const MediumText = styled.span`
  color: ${({ theme }) => (theme.color ? theme.color : secondaryTextColor)};
  font-size: ${mediumFontSize}px;
  font-weight: ${({ theme }) => (theme.fontWeight ? theme.fontWeight : "bold")};
  display: ${({ theme }) => (theme.display ? theme.display : "inline")};
  line-height: ${mediumFontSize * 1.5}px;
`;

export const LargeText = styled.span`
  color: ${({ theme }) => (theme.color ? theme.color : secondaryTextColor)};
  font-size: ${largeFontSize}px;
  font-weight: ${({ theme }) => (theme.fontWeight ? theme.fontWeight : "bold")};
  display: ${({ theme }) => (theme.display ? theme.display : "inline")};
  line-height: ${largeFontSize * 1.5}px;
`;

export const FormRequiredItemText = styled.span`
  color: ${primaryTextColor};
  font-size: ${mediumFontSize}px;
  font-weight: bold;
  line-height: ${mediumFontSize * 1.5}px;
  ::after {
    content: "*必須";
    color: ${warningColor};
    font-size: ${mediumFontSize * 0.8}px;
  }
`;

export const FormOptionalItemText = styled.span`
  color: ${primaryTextColor};
  font-size: ${mediumFontSize}px;
  font-weight: bold;
  line-height: ${mediumFontSize * 1.5}px;
  ::after {
    content: "*必須";
    color: ${secondaryTextColor};
    font-size: ${mediumFontSize * 0.8}px;
  }
`;

export const InputButton = styled.input`
  display: inline-block;
  width: 72px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: ${({ theme }) =>
    theme.background ? theme.background : accentColor};
  border: 2px solid transparent;
  border-radius: 5px;
  color: ${({ theme }) => (theme.color ? theme.color : invertColor)};
  font-size: ${mediumFontSize}px;
  text-align: center;
  text-decoration: none;
  transition: 0.25s;
  border-style: solid;
  &:hover {
    background-color: ${({ theme }) =>
      theme.color ? theme.color : invertColor};
    border-color: currentColor;
    color: ${({ theme }) =>
      theme.background ? theme.background : accentColor};
  }
  &:focus {
    background-color: ${({ theme }) =>
      theme.color ? theme.color : invertColor};
    border-color: currentColor;
    color: ${({ theme }) =>
      theme.background ? theme.background : accentColor};
  }
`;

export const InputTextBox = styled.input`
  width: ${({ theme }) => (theme.width ? theme.width + "px" : "100%")};
  padding: 8px 12px;
  padding-left: ${({ theme }) => (theme.paddingLeft ? theme.paddingLeft : 8)}px;
  font-size: ${mediumFontSize}px;
  color: ${firstColor};
  border: 1px solid ${dividerColor};
  border-radius: 5px;
  box-sizing: border-box;
  :hover {
    border-color: ${firstColor};
    border: 1px solid ${firstColor};
  }
  :focus {
    border-color: ${secondColor};
    border: 2px solid ${secondColor};
    outline: 0;
  }
  ::placeholder {
    color: ${placeholderColor};
  }
  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
  }
`;

export const PriceLap = styled.div`
  color: ${placeholderColor};
  width: 100%;
  position: relative;
  &::before {
    content: "￥";
    position: absolute;
    top: 10px;
    left: 5px;
    font-size: ${mediumFontSize}px;
  }
  &:hover {
    color: ${firstColor};
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexBlock = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 300px) {
    flex-direction: row;
  }
`;
export const RangeTilde = styled.div`
  margin: auto;
  text-align: center;
  transform: rotate(90deg);
  padding-left: 8px;
  padding-right: 8px;
  color: ${dividerColor};
  @media (min-width: 300px) {
    transform: rotate(0deg);
    position: static;
  }
`;

export const SearchBlock = styled.div`
  display: block;
  width: 100%;
`;

export const CheckBox = styled.input`
  display: none;
`;

export const CheckBoxText = styled(ExtraSmallText)`
  padding-left: 24px;
  position: relative;
  ::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid ${dividerColor};
    border-radius: 5px;
  }
  :hover::before {
    border: 1px solid ${firstColor};
  }
`;

export const CheckBoxWrapper = styled.div`
  & ${CheckBox}:checked + ${CheckBoxText}::before {
    background-color: ${accentColor};
  }
  & ${CheckBox}:checked + ${CheckBoxText}::after {
    content: "";
    display: block;
    position: absolute;
    top: -0.5px;
    left: 5px;
    width: 5px;
    height: 10px;
    transform: rotate(40deg);
    border-bottom: 2px solid ${invertColor};
    border-right: 2px solid ${invertColor};
  }
`;

export const CheckBoxLabel = styled.label`
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  width: 100%;
  @media (min-width: 300px) {
    width: 48%;
    :nth-child(2n + 1) {
      clear: both;
    }
  }
`;
export const InfoHeader = styled.h3`
  color: ${primaryTextColor};
  padding-left: 8px;
  margin-bottom: 20px;
  border-left: 5px solid ${firstColor};
  line-height: 1.3;
`;

export const InfoText = styled.p`
  color: ${primaryTextColor};
  line-height: 1.5;
`;

export const InfoBlock = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const InfoFlame = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.height}px;
  background: ${backgroundColor};
  position: absolute;
  top: ${topBarHeight}px;
  z-index: 2; // 他の表示の結果をそのままに戻れるためにあえてかぶせている
`;

export const InfoContents = styled.div`
  width: ${({ theme }) => theme.width}px;
  height: ${({ theme }) => theme.height}px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24;
  overflow: scroll;
`;

export const FormContents = styled.form`
  display: flex;
  flex-flow: column;
  gap: 12px 0px;
  width: ${({ theme }) => theme.width}px;
  height: ${({ theme }) => theme.height}px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 24;
  overflow: scroll;
`;
export const AccordionCheckbox = styled.label`
  position: absolute;
  top: -17px;
  left: 50%;
  display: block;
  ::after {
    content: "";
    display: block;
    position: absolute;
  }
  ::before {
    content: "";
    top: -8px;
    left: -8px;
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid ${dividerColor};
    border-radius: 50%;
  }
  ::after {
    top: 0px;
    width: 5px;
    height: 5px;
    border-right: 1px solid ${dividerColor};
    border-top: 1px solid ${dividerColor};
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    transition: transform 0.3s;
  }
`;
export const AccordionContent = styled.div`
  height: 0px;
  padding: 0 20px;
  visibility: hidden;
`;

export const AccordionInput = styled.input`
  top: -10px;
  display: none;
  :checked + ${AccordionCheckbox}+${AccordionContent} {
    height: 100%;
    visibility: visible;
  }
  :checked + ${AccordionCheckbox}::after {
    transition: transform 0.3s;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
`;
