import GoogleMapReact, { MapOptions, Maps } from "google-map-react";
import { centerType, hallType, searchResultType } from "my-types";
import React, { Dispatch, SetStateAction } from "react";
import Icon from "./pin_42_29.png";
function Map(props: {
  searchResult: searchResultType;
  setShowConcerts: Dispatch<SetStateAction<boolean>>;
  setHall: Dispatch<SetStateAction<hallType>>;
  center: centerType;
  zoom: number;
}): JSX.Element {
  // ピンを立てる準備
  const pins: {
    lat: number;
    lng: number;
    concert_count: number;
  }[] = [];
  if (Object.keys(props.searchResult)[0] != "") {
    Object.keys(props.searchResult).forEach((key) => {
      pins.push({
        lat: Number(key.split(",")[0]),
        lng: Number(key.split(",")[1]),
        concert_count: Number(
          Object.keys(props.searchResult[key].concerts).length
        ),
      });
    });
  }

  const Pin = (props: { lat: number; lng: number; concert_count: number }) => {
    // px を直接指定しているのをうまく変数にしたい気持ちはある
    const pinHeight = 42;
    const pinWidth = 29;
    const top = -0.75 * pinHeight;
    const left = -0.5 * pinWidth;
    const circleDiameter = pinWidth * 0.65;
    const circleLeft = (pinWidth - circleDiameter) / 2 - 1;
    const circleTop = pinHeight / 10;
    return (
      <div
        style={{
          position: "absolute",
          top: `${top}px`,
          left: `${left}px`,
        }}
      >
        <div
          style={{
            background: "#ffffff",
            borderRadius: " 50%",
            color: "#000000",
            height: `${circleDiameter}px`,
            width: `${circleDiameter}px`,
            border: "1px solid transparent",
            position: "absolute",
            top: `${circleTop}px`,
            left: `${circleLeft}px`,
            textAlign: "center",
            lineHeight: `${circleDiameter}px`, // 文字列を上下中央揃えする
          }}
        >
          {props.concert_count}
        </div>
        <img src={Icon} width={29} height={42} />
      </div>
    );
  };

  // 地図のデザインの設定
  const createMapOptions = (maps: Maps): MapOptions => {
    return {
      styles: [
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              color: "#D3EDFB",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 17,
            },
          ],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 29,
            },
            {
              weight: 0.2,
            },
          ],
        },
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [
            {
              lightness: 50,
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 18,
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "geometry",
          stylers: [
            {
              color: "#f5f5f5",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [
            {
              color: "#dedede",
            },
            {
              lightness: 21,
            },
          ],
        },
        {
          elementType: "labels.text.stroke",
          stylers: [
            {
              visibility: "on",
            },
            {
              color: "#ffffff",
            },
            {
              lightness: 16,
            },
          ],
        },
        {
          elementType: "labels.text.fill",
          stylers: [
            {
              saturation: 36,
            },
            {
              color: "#333333",
            },
            {
              lightness: 40,
            },
          ],
        },
        {
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit.station",
          elementType: "labels.icon",
          stylers: [
            { visibility: "simplified" },
            { color: "#727171" },
            { saturation: "-100" },
          ],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [
            {
              color: "#f2f2f2",
            },
            {
              lightness: 19,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 20,
            },
          ],
        },
        {
          featureType: "administrative",
          elementType: "geometry.stroke",
          stylers: [
            {
              color: "#fefefe",
            },
            {
              lightness: 17,
            },
            {
              weight: 1.2,
            },
          ],
        },
      ],
    };
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <GoogleMapReact
        zoom={props.zoom}
        center={props.center}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAP_API_KEY || "",
        }}
        options={createMapOptions}
        onChildClick={(key: string) => {
          props.setShowConcerts(true);
          props.setHall(props.searchResult[key]);
        }}
      >
        {pins.map(
          (pin: { lat: number; lng: number; concert_count: number }) => (
            <Pin
              lat={pin.lat}
              lng={pin.lng}
              key={`${pin.lat},${pin.lng}`}
              concert_count={pin.concert_count}
            />
          )
        )}
      </GoogleMapReact>
    </div>
  );
}
export default Map;
