import React from "react";
import {
  FlexBlock,
  InputTextBox,
  MediumText,
  PriceLap,
  RangeTilde,
  SearchBlock,
} from "./style";

function SearchPrice(props: {
  title: string;
  changeFunction: any;
}): JSX.Element {
  const title = props.title;
  const changeFunction = props.changeFunction;
  return (
    <SearchBlock>
      <MediumText>{title}</MediumText>
      <FlexBlock>
        <PriceLap>
          <InputTextBox
            theme={{ paddingLeft: 24 }}
            type="number"
            min="0"
            name="minPrice"
            onChange={changeFunction}
            placeholder="0"
          />
        </PriceLap>
        <RangeTilde>~</RangeTilde>
        <PriceLap>
          <InputTextBox
            theme={{ paddingLeft: 24 }}
            type="number"
            min="0"
            name="maxPrice"
            onChange={changeFunction}
            placeholder="上限なし"
          />
        </PriceLap>
      </FlexBlock>
    </SearchBlock>
  );
}

export default SearchPrice;
