import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import { contactType } from "my-types";
import React, { useState } from "react";
import styled from "styled-components";
import { contactDefaultValue } from "./constants";
import useWindowDimensions from "./GetWindowSize";
import {
  accentColor,
  backgroundColor,
  dividerColor,
  firstColor,
  FormContents,
  FormRequiredItemText,
  InfoFlame,
  InfoHeader,
  InputButton,
  primaryTextColor,
  safeColor,
  secondaryTextColor,
  secondColor,
  topBarHeight,
  warningColor,
} from "./style";
const ContactTextArea = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid ${dividerColor};
  border-radius: 5px;
  box-sizing: border-box;
  height: 120px;
  :hover {
    border-color: ${firstColor};
    border: 1px solid ${firstColor};
  }
  :focus {
    border-color: ${secondColor};
    border: 2px solid ${secondColor};
    outline: 0;
  }
  :invalid:not(:focus) {
    border: ${warningColor} 2px solid;
  }
  :valid:not(:focus) {
    border: ${safeColor} 2px solid;
  }
`;

const ContactInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid ${dividerColor};
  border-radius: 5px;
  box-sizing: border-box;
  :hover {
    border-color: ${firstColor};
    border: 1px solid ${firstColor};
  }
  :focus {
    border-color: ${secondColor};
    border: 2px solid ${secondColor};
    outline: 0;
  }
  :invalid:not(:focus) {
    border: ${warningColor} 2px solid;
  }
  :valid:not(:focus) {
    border: ${safeColor} 2px solid;
  }
`;

const radioButtonTheme = createTheme({
  palette: {
    primary: { main: accentColor },
  },
});

const RadioButtonLabel = ({
  value,
  label,
}: {
  value: string;
  label: string;
}) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" />}
      label={label}
      style={{ color: primaryTextColor }}
    />
  );
};
function Contact(): JSX.Element {
  const [contact, setContact] = useState<contactType>(contactDefaultValue);
  const [isSuccess, setIsSuccess] = useState<string>("before");
  const handleContactChange = (event: any) => {
    setContact((state) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };
  const handleSubmit = (event: any) => {
    if (event.cancelable) {
      // この if 文によって次のエラーを防いでいる。Ignored attempt to cancel a touchmove event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.
      // ソースはこちら https://stackoverflow.com/questions/26478267/touch-move-getting-stuck-ignored-attempt-to-cancel-a-touchmove
      //preventDefault はsubmit でページを更新しないために入れる
      event.preventDefault();
    }
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });
    setIsSuccess("processing");
    instance.post("/contact", contact).then((res) => {
      // 問い合わせが成功したかどうかの結果を取得する
      if (res.status == 200) {
        setIsSuccess("success");
      } else {
        setIsSuccess("failure");
      }
    });
  };

  const height = useWindowDimensions()["height"];
  const width = useWindowDimensions()["width"];

  return (
    <InfoFlame>
      <FormContents
        theme={{
          width: Math.min(600, width) - 24,
          height: height - topBarHeight,
        }}
        onSubmit={handleSubmit}
      >
        <div>
          <InfoHeader>問い合わせ</InfoHeader>
          <FormRequiredItemText>問い合わせ種別</FormRequiredItemText>
          <RadioGroup
            aria-label="gender"
            name="contactType"
            value={contact.contactType}
            onChange={handleContactChange}
          >
            <MuiThemeProvider theme={radioButtonTheme}>
              <RadioButtonLabel value="バグ報告" label="バグ報告" />
              <RadioButtonLabel value="改善要望" label="改善要望" />
              <RadioButtonLabel value="訂正依頼" label="訂正依頼" />
              <RadioButtonLabel value="その他" label="その他" />
            </MuiThemeProvider>
          </RadioGroup>
        </div>
        <div>
          <FormRequiredItemText>メールアドレス</FormRequiredItemText>
          <ContactInput
            type="email"
            name="email"
            required
            pattern=".+\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]"
            value={contact.email}
            onChange={handleContactChange}
          />
        </div>
        <div>
          <FormRequiredItemText>問い合わせ内容</FormRequiredItemText>
          <ContactTextArea
            name="contactText"
            required
            value={contact.contactText}
            onChange={handleContactChange}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          {isSuccess == "before" && <InputButton value="送信" type="submit" />}
          {isSuccess == "success" && (
            <InputButton theme={{ background: secondColor }} value="成功" />
          )}
          {isSuccess == "error" && (
            <InputButton
              theme={{ background: backgroundColor, color: secondaryTextColor }}
              value="エラー"
            />
          )}
          {isSuccess == "processing" && <InputButton value="送信中" />}
        </div>
      </FormContents>
    </InfoFlame>
  );
}

export default Contact;
