import DateFnsUtils from "@date-io/date-fns";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import format from "date-fns/format";
import jaLocale from "date-fns/locale/ja";
import { dateNull } from "my-types";
import React from "react";
import {
  FlexBlock,
  MediumText,
  RangeTilde,
  SearchBlock,
  secondColor,
} from "./style";

function SearchDate(props: {
  title: string;
  setState: any;
  state: any;
}): JSX.Element {
  const title = props.title;
  const setSearchInput = props.setState;
  const searchInput = props.state;
  const dateChange = (date: dateNull, name: string) => {
    let inputDateTime: null | number = null;
    if (date) {
      inputDateTime = new Date(
        date.setMinutes(
          date.getMinutes() + new Date().getTimezoneOffset() + 9 * 60
        )
      ).getTime();
    }
    console.log(inputDateTime);
    setSearchInput((state: any) => ({ ...state, [name]: inputDateTime }));
  };
  class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: Date) {
      return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date: Date) {
      return format(date, "MMMd日", { locale: this.locale });
    }
  }
  const customTheme = createTheme({
    palette: {
      primary: {
        main: secondColor,
      },
    },
  });

  return (
    <SearchBlock>
      <MediumText>{title}</MediumText>
      <FlexBlock>
        <MuiThemeProvider theme={customTheme}>
          <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
            <DatePicker
              value={searchInput.minDate}
              minDate={new Date()}
              onChange={(date) => dateChange(date, "minDate")}
              variant="inline"
              format="yyyy/MM/dd"
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
        <RangeTilde>~</RangeTilde>
        <MuiThemeProvider theme={customTheme}>
          <MuiPickersUtilsProvider utils={ExtendedUtils} locale={jaLocale}>
            <DatePicker
              value={searchInput.maxDate}
              minDate={new Date()}
              onChange={(date) => dateChange(date, "maxDate")}
              variant="inline"
              format="yyyy/MM/dd"
            />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </FlexBlock>
    </SearchBlock>
  );
}

export default SearchDate;
