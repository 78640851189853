import { makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { Help, Room } from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import ListIcon from "@material-ui/icons/List";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import React, { Dispatch, SetStateAction } from "react";
import { accentColor, secondColor } from "./style";
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  tab: {
    width: "130px",
    minWidth: "130px",
  },
}));
function TopBar(props: {
  tabValue: number;
  setTabValue: Dispatch<SetStateAction<number>>;
}): JSX.Element {
  const classes = useStyles();
  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    props.setTabValue(newValue);
  };
  return (
    <AppBar style={{ background: secondColor }}>
      <Tabs
        value={props.tabValue}
        onChange={handleChange}
        variant="scrollable"
        TabIndicatorProps={{ style: { background: accentColor } }}
      >
        <Typography
          style={{
            marginLeft: "16px",
            marginRight: "auto",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          Concert Map (β版)
        </Typography>
        <Tab label="地図表示" icon={<Room />} classes={{ root: classes.tab }} />
        <Tab
          label="リスト表示"
          icon={<ListIcon />}
          classes={{ root: classes.tab }}
        />
        <Tab
          label="準備中"
          icon={<NoteAddIcon />}
          classes={{ root: classes.tab }}
        />
        <Tab
          label="問い合わせ"
          icon={<ContactMailIcon />}
          classes={{ root: classes.tab }}
        />
        <Tab
          label="サイトについて"
          icon={<Help />}
          classes={{ root: classes.tab }}
        />
        <Tab
          label="準備中"
          icon={<AccountCircleIcon />}
          style={{ marginRight: "16px" }}
          classes={{ root: classes.tab }}
        />
      </Tabs>
    </AppBar>
  );
}

export default TopBar;
