import { concertType } from "my-types";
import React from "react";
import styled from "styled-components";
import {
  basePadding,
  dividerColor,
  Link,
  MediumText,
  smallPadding,
  SmallText,
} from "./style";

const DateFrame = styled.div`
  padding: ${smallPadding}px;
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 0px 8px;
  height: 18px;
  &::after {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${dividerColor};
  }
`;
const DateTime = ({ date, time }: { date: string; time: string }) => {
  const dateSplit = date.split("-");
  const year = Number(dateSplit[0]);
  const month = Number(dateSplit[1]);
  const day = Number(dateSplit[2]);
  const timeSplit = time.split(":");
  const hour = timeSplit[0];
  const minute = timeSplit[1];
  const dayOfTheWeek = ["日", "月", "火", "水", "木", "金", "土"][
    new Date(date).getDay()
  ];

  return (
    <DateFrame>
      <SmallText>{year}</SmallText>
      <MediumText>
        {month}/{day}
        <SmallText> ({dayOfTheWeek})</SmallText>
      </MediumText>
      <SmallText>
        {hour}:{minute}
      </SmallText>
    </DateFrame>
  );
};

const MapViewElementFlame = styled.div`
  width: 120;
  background: white;
  padding: ${basePadding}px;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const ConcertName = styled(MediumText)`
  font-weight: bold;
`;
const ConcertNameFrame = styled.div`
  padding-bottom: 12px;
  padding-top: 12px;
`;
const ItemBlock = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;
const ConcertImage = styled.img`
  max-width: 100%;
`;
const Indent = styled.div`
  padding-left: 14px;
`;
function MapViewElement(concert: concertType): JSX.Element {
  const concertDate = concert.concert_date;
  const players = concert.players;
  const flyerUrl = concert.flyer_url;
  // const hallName = concert.hall_name;
  const programs = concert.programs;
  let existsPlayers = null;
  if (Object.keys(players)[0]) {
    existsPlayers = true;
  }
  let existsProgram = null;
  Object.keys(programs).forEach((key) => {
    if (key != "null") {
      existsProgram = true;
    }
  });

  return (
    <MapViewElementFlame>
      <DateTime
        date={concert.concert_date}
        time={concert.start_time}
      ></DateTime>
      <ConcertNameFrame>
        {concert.concert_url ? (
          <ConcertName>
            <Link href={concert.concert_url} target="blank">
              {concert.title}
            </Link>
          </ConcertName>
        ) : (
          <ConcertName>{concert.title}</ConcertName>
        )}
      </ConcertNameFrame>
      {flyerUrl && (
        <ItemBlock>
          <ConcertImage src={flyerUrl} />
        </ItemBlock>
      )}
      {existsPlayers &&
        (() => {
          const playersComponent: Array<JSX.Element> = [];
          Object.keys(players).forEach((key) => {
            playersComponent.push(
              <div key={key}>
                <SmallText>{players[key]}</SmallText>
              </div>
            );
          });
          return (
            <ItemBlock>
              <MediumText>出演</MediumText>
              <SmallText>{playersComponent}</SmallText>
            </ItemBlock>
          );
        })()}
      {existsProgram &&
        (() => {
          const programsComponent: Array<JSX.Element> = [];
          Object.keys(programs).forEach((key) => {
            programsComponent.push(
              <div key={key}>
                <SmallText>
                  {programs[key].composer}
                  <Indent>{programs[key].music_name}</Indent>
                </SmallText>
              </div>
            );
          });
          return (
            <ItemBlock>
              <MediumText>プログラム</MediumText>
              <SmallText>{programsComponent}</SmallText>
            </ItemBlock>
          );
        })()}
    </MapViewElementFlame>
  );
}

export default MapViewElement;
