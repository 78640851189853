import React from "react";
import {
  AccordionCheckbox,
  AccordionContent,
  AccordionInput,
  CheckBox,
  CheckBoxLabel,
  CheckBoxText,
  CheckBoxWrapper,
  MediumText,
} from "./style";

function SearchAccordionCheckbox(props: {
  title: string;
  checkboxHash: { [key: string]: string[] };
  parentItemName: string;
  childItemName: string;
  changeFunction: any;
  state: any;
}): JSX.Element {
  const title = props.title;
  const checkboxHash = props.checkboxHash;
  const parentItemName = props.parentItemName;
  const childItemName = props.childItemName;
  const changeFunction = props.changeFunction;
  const state = props.state;
  return (
    <div>
      <MediumText>{title}</MediumText>
      {(() => {
        const items: Array<JSX.Element> = [];
        for (const key in checkboxHash) {
          // region のチェックボックスを追加する
          items.push(
            <div key={key}>
              <CheckBoxLabel style={{ width: "30%" }}>
                <CheckBoxWrapper>
                  <CheckBox
                    type="checkbox"
                    onChange={changeFunction}
                    value={state[parentItemName][key]}
                    name={key}
                    checked={state[parentItemName][key]}
                  />
                  <CheckBoxText>{key}</CheckBoxText>
                </CheckBoxWrapper>
              </CheckBoxLabel>
            </div>
          );
          // region 内の都道府県のチェックボックスを追加する
          const prefectures: Array<JSX.Element> = [];
          for (const value of checkboxHash[key]) {
            prefectures.push(
              <CheckBoxLabel key={value}>
                <CheckBoxWrapper>
                  <CheckBox
                    type="checkbox"
                    onChange={changeFunction}
                    value={state[childItemName][value]}
                    name={value}
                    checked={state[childItemName][value]}
                  />
                  <CheckBoxText>{value}</CheckBoxText>
                </CheckBoxWrapper>
              </CheckBoxLabel>
            );
          }
          items.push(
            <div style={{ position: "relative" }} key={key + "div"}>
              <AccordionInput id={key} type="checkbox" />
              <AccordionCheckbox htmlFor={key}></AccordionCheckbox>
              <AccordionContent>{prefectures}</AccordionContent>
            </div>
          );
        }

        return <div>{items}</div>;
      })()}
    </div>
  );
}
export default SearchAccordionCheckbox;
