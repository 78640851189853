import React from "react";
import { defaultCheckedValues } from "./constants";
import {
  CheckBox,
  CheckBoxLabel,
  CheckBoxText,
  CheckBoxWrapper,
  MediumText,
} from "./style";

function SearchCheckbox(props: {
  title: string;
  checkboxHash: { [key: string]: string };
  changeFunction: any;
}): JSX.Element {
  const title = props.title;
  const checkboxHash = props.checkboxHash;
  const changeFunction = props.changeFunction;
  return (
    <div>
      <MediumText>{title}</MediumText>
      {(() => {
        const items: Array<JSX.Element> = [];
        Object.keys(checkboxHash).forEach((key) => {
          items.push(
            <CheckBoxLabel key={key}>
              <CheckBoxWrapper>
                <CheckBox
                  type="checkbox"
                  onChange={changeFunction}
                  name={key}
                  defaultChecked={defaultCheckedValues.includes(
                    checkboxHash[key]
                  )}
                />
                <CheckBoxText>{checkboxHash[key]}</CheckBoxText>
              </CheckBoxWrapper>
            </CheckBoxLabel>
          );
        });
        return <div>{items}</div>;
      })()}
    </div>
  );
}

export default SearchCheckbox;
