import { concertType, searchResultType } from "my-types";
import React from "react";
import { FixedSizeList } from "react-window";
import styled from "styled-components";
import useWindowDimensions from "./GetWindowSize";
import {
  backgroundColor,
  basePadding,
  dividerColor,
  ExtraSmallText,
  LargeText,
  Link,
  MediumText,
  secondaryTextColor,
  smallPadding,
  SmallText,
  topBarHeight,
} from "./style";

const PCDateFrame = styled.div`
  padding: ${basePadding}px;
  position: relative;
  grid-row: 1/3;
  grid-column: 1/2;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  &::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    top: 0;
    right: 0;
    background: ${dividerColor};
    margin-left: 10px;
  }
`;
const PCListElementDateTime = ({
  date,
  time,
}: {
  date: string;
  time: string;
}) => {
  const dateSplit = date.split("-");
  const year = Number(dateSplit[0]);
  const month = Number(dateSplit[1]);
  const day = Number(dateSplit[2]);
  const timeSplit = time.split(":");
  const hour = timeSplit[0];
  const minute = timeSplit[1];
  const dayOfTheWeek = ["日", "月", "火", "水", "木", "金", "土"][
    new Date(date).getDay()
  ];

  return (
    <PCDateFrame>
      <SmallText>{year}</SmallText>
      <LargeText>
        {month}/{day}
        <SmallText> ({dayOfTheWeek})</SmallText>
      </LargeText>
      <SmallText>
        {hour}:{minute}
      </SmallText>
    </PCDateFrame>
  );
};
const PCTitle = styled(MediumText)`
  display: grid;
  padding: ${smallPadding}px;
  grid-row: 2/3;
  grid-column: 2/3;
  padding-left: 24px;
  line-height: 24px;
  font-weight: bold;
`;
const PCListElementTitle = ({
  title,
  url,
}: {
  title: string;
  url: string | null;
}) => {
  const maxTitleLength = 72;
  const titleTrimming =
    title.length > maxTitleLength
      ? title.slice(0, maxTitleLength) + "..."
      : title;
  return (
    <PCTitle>
      {url ? (
        <Link href={url} target="blank">
          {titleTrimming}
        </Link>
      ) : (
        <div>{titleTrimming}</div>
      )}
    </PCTitle>
  );
};

const PCPlaceFrame = styled(SmallText)`
  display: block;
  padding: ${smallPadding}px;
  padding-left: 24px;
`;
const PCListElementPlace = ({
  prefecture,
  hallPlace,
  hallName,
  hallUrl,
}: {
  prefecture: string;
  hallPlace: string;
  hallName: string;
  hallUrl: string | null;
}) => {
  return (
    <PCPlaceFrame>
      {prefecture + " "}
      {hallUrl ? (
        <Link href={hallUrl} target="blank">
          {hallPlace} {hallName}
        </Link>
      ) : (
        <div>
          {hallPlace} {hallName}
        </div>
      )}
    </PCPlaceFrame>
  );
};
const MobileDateFrame = styled.div`
  padding: ${smallPadding}px;
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 0px 8px;
  height: 18px;
  &::after {
    content: "";
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${dividerColor};
  }
`;
const MobileListElementDateTime = ({
  date,
  time,
}: {
  date: string;
  time: string;
}) => {
  const dateSplit = date.split("-");
  const year = Number(dateSplit[0]);
  const month = Number(dateSplit[1]);
  const day = Number(dateSplit[2]);
  const timeSplit = time.split(":");
  const hour = timeSplit[0];
  const minute = timeSplit[1];
  const dayOfTheWeek = ["日", "月", "火", "水", "木", "金", "土"][
    new Date(date).getDay()
  ];

  return (
    <MobileDateFrame>
      <SmallText>{year}</SmallText>
      <MediumText>
        {month}/{day}
        <SmallText> ({dayOfTheWeek})</SmallText>
      </MediumText>
      <SmallText>
        {hour}:{minute}
      </SmallText>
    </MobileDateFrame>
  );
};
const MobileTitle = styled.div`
  height: 72px;
  display: flex;
  padding: ${smallPadding}px;
  align-items: center;
`;
const MobileListElementTitle = ({
  title,
  url,
}: {
  title: string;
  url: string | null;
}) => {
  return (
    <MobileTitle>
      <MediumText>
        {url ? (
          <Link href={url} target="blank">
            {title}
          </Link>
        ) : (
          <div>{title}</div>
        )}
      </MediumText>
    </MobileTitle>
  );
};

const searchResultCountHeight = 48;
const SearchResultCountFlame = styled.div`
  background: ${backgroundColor};
  width: ${({ theme }) => theme.width}px;
  height: ${searchResultCountHeight}px;
  position: relative;
`;
const SearchResultCount = styled.div`
  background: ${backgroundColor};
  height: 24px;
  width: ${({ theme }) => theme.width}px;
  font-size: 18px;
  color: ${secondaryTextColor};
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  &::after {
    content: "";
    display: block;
    height: 1px;
    width: ${({ theme }) => theme.width}px;
    position: absolute;
    bottom: 0;
    background: ${dividerColor};
  }
`;
const MobilePlaceFrame = styled.div`
  color: ${secondaryTextColor};
  display: block;
  padding: ${smallPadding}px;
  display: flex;
  align-items: flex-end;
  gap: 0px 8px;
  height: 18px;
`;
const MobileListElementPlace = ({
  prefecture,
  hallPlace,
  hallName,
  hallUrl,
}: {
  prefecture: string;
  hallPlace: string;
  hallName: string;
  hallUrl: string | null;
}) => {
  return (
    <MobilePlaceFrame>
      <ExtraSmallText>{prefecture}</ExtraSmallText>
      {hallUrl ? (
        <ExtraSmallText>
          <Link href={hallUrl} target="blank">
            {hallPlace} {hallName}
          </Link>
        </ExtraSmallText>
      ) : (
        <ExtraSmallText>
          {hallPlace} {hallName}
        </ExtraSmallText>
      )}
    </MobilePlaceFrame>
  );
};

const ListView = ({
  searchResult,
  showSearch,
}: {
  searchResult: searchResultType;
  showSearch: boolean;
}): JSX.Element => {
  // 画面の大きさの取得
  const windowDimensions = useWindowDimensions();
  const windowHeight = windowDimensions["height"];
  const windowWidth = windowDimensions["width"];

  // 検索入力コンポーネントの幅
  const searchWidth = 300;

  // レスポンシブデザインの境界となる幅
  const responsiveBorder = 500;

  // 検索結果を取得する。
  const searchResultList: Array<concertType> = [];
  Object.values(searchResult).forEach((concerts) => {
    Object.values(concerts.concerts).forEach((concert) => {
      searchResultList.push(concert);
    });
  });
  searchResultList.sort((a, b) => {
    // 日付の昇順でソートする
    const aSplit = a.concert_date.split("-");
    const bSplit = b.concert_date.split("-");
    const year = Number(aSplit[0]) - Number(bSplit[0]);
    const month = Number(aSplit[1]) - Number(bSplit[1]);
    const day = Number(aSplit[2]) - Number(bSplit[2]);
    return year * 12 * 31 + month * 31 + day;
  });

  // リスト表示の枠のスタイル
  const listOverviewStyle = {
    background: backgroundColor,
  };

  // PC、モバイル共通の要素のスタイル
  const commonListElementStyle = {
    background: "white",
    marginLeft: "auto",
    marginRight: "auto",
    padding: basePadding,
  };

  // PC専用の要素のスタイル
  const listHeight = windowHeight - topBarHeight - searchResultCountHeight;
  const listWidth = Math.max(
    windowWidth - searchWidth * (showSearch ? 1 : 0),
    windowWidth > responsiveBorder ? 300 : windowWidth
  );
  const itemHeight = windowWidth > responsiveBorder ? 150 : 180;
  const listElementHeight = itemHeight - basePadding;
  // 子要素の空白が大きすぎると間延びした印象を与えるため上限を設ける
  const listElementWidth =
    windowWidth > responsiveBorder
      ? Math.max(Math.min(listWidth - 2 * basePadding, 800), responsiveBorder)
      : 300;

  const pcListElementStyle = {
    ...commonListElementStyle,
    height: listElementHeight - 2 * basePadding,
    width: listElementWidth - 2 * basePadding,
    display: "grid",
    gridTemplateColumns: "135px 1fr",
    gridTemplateRows: "30px 1fr",
  };
  const mobileListElementStyle = {
    ...commonListElementStyle,
    height: listElementHeight - 2 * basePadding,
    width: listElementWidth - 2 * basePadding,
  };

  const MobileListOverview = ({
    index,
    style,
  }: {
    index: number;
    style: any;
  }) => {
    const concert = searchResultList[index];
    return (
      <div style={style}>
        <div style={mobileListElementStyle}>
          <MobileListElementDateTime
            date={concert.concert_date}
            time={concert.start_time}
          />
          <MobileListElementPlace
            prefecture={concert.hall_prefecture}
            hallPlace={concert.hall_place}
            hallName={concert.hall_name}
            hallUrl={concert.hall_url}
          />
          <MobileListElementTitle
            title={concert.title}
            url={concert.concert_url}
          />
        </div>
      </div>
    );
  };

  const PCListOverview = ({ index, style }: { index: number; style: any }) => {
    const concert = searchResultList[index];
    return (
      <div style={style}>
        <div style={pcListElementStyle}>
          <PCListElementDateTime
            date={concert.concert_date}
            time={concert.start_time}
          />
          <PCListElementPlace
            prefecture={concert.hall_prefecture}
            hallPlace={concert.hall_place}
            hallName={concert.hall_name}
            hallUrl={concert.hall_url}
          />
          <PCListElementTitle title={concert.title} url={concert.concert_url} />
        </div>
      </div>
    );
  };

  // スマホで表示するコンポーネント
  const MobileFixedSizeList = () => {
    return (
      <div>
        <SearchResultCountFlame theme={{ width: listWidth }}>
          <SearchResultCount theme={{ width: listElementWidth }}>
            検索結果 {searchResultList.length} 件
          </SearchResultCount>
        </SearchResultCountFlame>
        <FixedSizeList
          height={listHeight}
          width={listWidth}
          itemSize={itemHeight}
          itemCount={searchResultList.length}
          overscanCount={5}
          style={listOverviewStyle}
        >
          {MobileListOverview}
        </FixedSizeList>
      </div>
    );
  };

  // スマホ以外で表示するコンポーネント
  const PCFixedSizeList = () => {
    return (
      <div>
        <SearchResultCountFlame theme={{ width: listWidth }}>
          <SearchResultCount theme={{ width: listElementWidth }}>
            検索結果 {searchResultList.length} 件
          </SearchResultCount>
        </SearchResultCountFlame>
        <FixedSizeList
          height={listHeight}
          width={listWidth}
          itemSize={itemHeight}
          itemCount={searchResultList.length}
          overscanCount={1}
          style={listOverviewStyle}
        >
          {PCListOverview}
        </FixedSizeList>
      </div>
    );
  };

  return (
    <div>
      {windowWidth >= responsiveBorder ? (
        <PCFixedSizeList />
      ) : (
        <MobileFixedSizeList />
      )}
    </div>
  );
};

export default ListView;
