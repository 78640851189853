import { CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { centerType, hallType, searchResultType } from "my-types";
import "normalize.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  centerDefaultValue,
  hallDefaultValue,
  searchResultDefaultValue,
} from "./constants";
import Contact from "./Contact";
import "./css/App.css";
import useWindowDimensions from "./GetWindowSize";
import ListView from "./ListView";
import Map from "./Map";
import MapView from "./MapView";
import { CloseButton, OpenButton } from "./parts";
import Search from "./Search";
import SiteInfo from "./SiteInfo";
import { accentColor, topBarHeight } from "./style";
import TopBar from "./TopBar";

function getZoom(
  lat_diff: number,
  lng_diff: number,
  height: number,
  width: number
): number {
  const height_diff = (500 / height) * lat_diff;
  const width_diff = (500 / width) * lng_diff;
  const max_diff = Math.max(height_diff, width_diff);
  // 大方針として log2 で計算すると程よい倍率で調整できる。定数は適当。
  const result = 8 - Math.log2(max_diff);
  return Math.min(result, 13);
}

const TransPosition = styled.div`
  position: absolute;
  transform: translateX(${({ theme }) => theme.pixel}px);
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-delay: 0s;
  top: ${topBarHeight}px;
  left: ${({ theme }) => theme.left}px;
  right: ${({ theme }) => theme.right}px;
`;

function App(): JSX.Element {
  const windowDimensions = useWindowDimensions();
  const height = windowDimensions["height"];
  const width = windowDimensions["width"];
  // ホールの型宣言とステート準備
  const [searchResult, setSearchResult] = useState<searchResultType>(
    searchResultDefaultValue
  );
  const [hall, setHall] = useState<hallType>(hallDefaultValue);
  const [center, setCenter] = useState<centerType>(centerDefaultValue);
  const [zoom, setZoom] = useState<number>(12);

  // ボタンに機能を持たせる
  const [showConcerts, setShowConcerts] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);

  // タブの切り替えの変数
  const [tabValue, setTabValue] = useState(1);

  // ローディングの変数
  const [isLoading, setIsLoading] = useState(true);

  // 検索ボタンを押した時の処理
  useEffect(() => {
    // 検索ボタンを押したときは毎回 hall component を非表示にする
    setShowConcerts(false);
    // 検索結果に応じてマップの拡大率と中心を変更する
    if (
      typeof Object.keys(searchResult)[0] !== "undefined" &&
      Object.keys(searchResult)[0] != ""
    ) {
      let max_lat = -200;
      let min_lat = 200;
      let max_lng = -200;
      let min_lng = 200;
      Object.keys(searchResult).forEach((key) => {
        const lat_lng = key.split(",");
        max_lat = Math.max(max_lat, Number(lat_lng[0]));
        min_lat = Math.min(min_lat, Number(lat_lng[0]));
        max_lng = Math.max(max_lng, Number(lat_lng[1]));
        min_lng = Math.min(min_lng, Number(lat_lng[1]));
      });
      // Top Bar とホール名の表示の分だけ少し下にずらすための変数を作成
      const under_diff = (max_lat - min_lat) / 8;
      setCenter({
        lat: (max_lat + min_lat) / 2 + under_diff,
        lng: (max_lng + min_lng) / 2,
      });
      const zoom = getZoom(max_lat - min_lat, max_lng - min_lng, height, width);
      setZoom(zoom);
    }
  }, [searchResult]);

  // リストViewの位置を定める
  const listViewBase = {
    transitionDuration: "0.3s",
    transitionTimingFunction: "ease-out",
    transitionDelay: "0x",
    transform: "translateX(300px)",
    position: "absolute" as const,
    top: topBarHeight,
  };
  const listViewPosition = {
    ...listViewBase,
    transform: "translateX(300px)",
  };
  const listViewTransPosition = {
    ...listViewBase,
    transform: "translateX(0px)",
  };
  return (
    <div>
      {isLoading ? (
        <CircularProgress
          style={{
            zIndex: 10,
            position: "absolute",
            top: height / 2,
            left: width / 2,
            color: accentColor,
          }}
        />
      ) : (
        <></>
      )}
      <div style={{ top: 0 }}>
        <TopBar tabValue={tabValue} setTabValue={setTabValue} />
        {tabValue == 4 ? <Contact /> : <></>}
        {tabValue == 5 ? <SiteInfo /> : <></>}
      </div>

      {tabValue == 1 ? (
        <Map
          searchResult={searchResult}
          setShowConcerts={setShowConcerts}
          setHall={setHall}
          center={center}
          zoom={zoom}
        />
      ) : (
        <></>
      )}
      {tabValue == 2 ? (
        <div style={showSearch ? listViewPosition : listViewTransPosition}>
          <ListView searchResult={searchResult} showSearch={showSearch} />
        </div>
      ) : (
        <></>
      )}

      <TransPosition theme={{ pixel: showSearch ? 0 : -300, left: 0 }}>
        <Search
          setSearchResult={setSearchResult}
          setShowSearch={setShowSearch}
          setIsLoading={setIsLoading}
        />
      </TransPosition>

      {tabValue == 1 ? (
        <TransPosition theme={{ pixel: showConcerts ? 0 : 300, right: 0 }}>
          {<MapView hall={hall} />}
        </TransPosition>
      ) : (
        <></>
      )}

      <div style={{ top: topBarHeight, position: "absolute" }}>
        {showSearch ? (
          <IconButton onClick={() => setShowSearch(false)}>
            <CloseButton />
          </IconButton>
        ) : (
          <IconButton onClick={() => setShowSearch(true)}>
            <OpenButton />
          </IconButton>
        )}
      </div>
      {tabValue == 1 ? (
        <div style={{ top: topBarHeight, position: "absolute", right: 0 }}>
          {showConcerts ? (
            <IconButton onClick={() => setShowConcerts(false)}>
              <CloseButton />
            </IconButton>
          ) : (
            <IconButton onClick={() => setShowConcerts(true)}>
              <OpenButton />
            </IconButton>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default App;
