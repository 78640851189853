// ジャンルのコンポーネントを作成する
export const genreHash: { [key: string]: string } = {
  classic: "クラシック",
  pops: "ポップス",
  jazz: "ジャズ",
  movie: "映画",
  musical: "ミュージカル",
  game: "ゲーム",
  anime: "アニメ",
  japanese: "邦楽",
  ballet: "バレエ",
  genreothers: "その他",
};

export const performingStyleHash: { [key: string]: string } = {
  solo: "ソロ",
  chambermusic: "室内楽",
  orchestra: "管弦楽",
  opera: "オペラ",
  brassband: "吹奏楽",
  band: "バンド",
  chorus: "合唱",
  performingothers: "その他",
};

export const purchaseStatusHash: { [key: string]: string } = {
  noticket: "無料",
  sale: "販売中",
  beforesale: "販売前",
  soldout: "完売",
};

export const prefectureHash: { [key: string]: string } = {
  北海道: "北海道",
  青森県: "青森県",
  岩手県: "岩手県",
  宮城県: "宮城県",
  秋田県: "秋田県",
  山形県: "山形県",
  福島県: "福島県",
  茨城県: "茨城県",
  栃木県: "栃木県",
  群馬県: "群馬県",
  埼玉県: "埼玉県",
  千葉県: "千葉県",
  東京都: "東京都",
  神奈川県: "神奈川県",
  新潟県: "新潟県",
  富山県: "富山県",
  石川県: "石川県",
  福井県: "福井県",
  山梨県: "山梨県",
  長野県: "長野県",
  岐阜県: "岐阜県",
  静岡県: "静岡県",
  愛知県: "愛知県",
  三重県: "三重県",
  滋賀県: "滋賀県",
  京都府: "京都府",
  大阪府: "大阪府",
  兵庫県: "兵庫県",
  奈良県: "奈良県",
  和歌山県: "和歌山県",
  鳥取県: "鳥取県",
  島根県: "島根県",
  岡山県: "岡山県",
  広島県: "広島県",
  山口県: "山口県",
  徳島県: "徳島県",
  香川県: "香川県",
  愛媛県: "愛媛県",
  高知県: "高知県",
  福岡県: "福岡県",
  佐賀県: "佐賀県",
  長崎県: "長崎県",
  熊本県: "熊本県",
  大分県: "大分県",
  宮崎県: "宮崎県",
  鹿児島県: "鹿児島県",
  沖縄県: "沖縄県",
};

export const regionHash: { [key: string]: string[] } = {
  関東: [
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
  ],
  近畿: [
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
  ],
};

export const defaultCheckedValues: [string] = ["関東"];

export const concertsDefaultValue = {
  0: {
    hall_name: "",
    hall_place: "",
    hall_url: "",
    hall_prefecture: "",
    concert_date: "",
    concert_url: "",
    flyer_url: "",
    title: "",
    genre1: "",
    genre2: "",
    genre3: "",
    movie_url: "",
    open_time: "",
    start_time: "",
    performing_style1: "",
    performing_style2: "",
    performing_style3: "",
    players: {
      "": "",
    },
    programs: {
      0: {
        composer: "",
        music_name: "",
      },
    },
    prefecture: "",
  },
};

export const hallDefaultValue = {
  concerts: concertsDefaultValue,
  hall_image: "",
  hall_place: "",
  hall_url: "",
  hall_prefecture: "",
};

export const searchResultDefaultValue = {
  "": hallDefaultValue,
};

export const centerDefaultValue = {
  lat: 35.68575,
  lng: 139.72521,
};

const maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 180);
export const searchDefaultValue = {
  minDate: new Date().getTime(),
  maxDate: maxDate.getTime(),
  genre: {
    classic: false,
    pops: false,
    jazz: false,
    movie: false,
    game: false,
    anime: false,
    rock: false,
    japanese: false,
    genreothers: false,
  },
  performingStyle: {
    solo: false,
    chambermusic: false,
    orchestra: false,
    brassband: false,
    opera: false,
    band: false,
    chorus: false,
    performingothers: false,
  },
  free_text: "",
  title: "",
  composer: "",
  player: "",
  music: "",
  purchaseStatus: {
    noticket: false,
    sale: false,
    beforesale: false,
    soldout: false,
  },
  minPrice: 0,
  maxPrice: 0,
  region: {
    近畿: false,
    関東: true,
  },
  prefecture: {
    茨城県: true,
    栃木県: true,
    群馬県: true,
    埼玉県: true,
    千葉県: true,
    東京都: true,
    神奈川県: true,
    三重県: false,
    滋賀県: false,
    京都府: false,
    大阪府: false,
    兵庫県: false,
    奈良県: false,
    和歌山県: false,
  },
};

export const contactDefaultValue = {
  contactType: "バグ報告",
  email: "",
  contactText: "",
};

export const sqlDefaultParameters = {
  minDate: String(new Date().getTime()),
  maxDate: String(maxDate.getTime()),
  genre: [],
  performingStyle: [],
  free_text: "",
  title: "",
  composer: "",
  player: "",
  music: "",
  purchaseStatus: [],
  minPrice: 0,
  maxPrice: 0,
  prefecture: [],
};
