import AddCircleIcon from "@material-ui/icons/AddCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import React from "react";
import { accentColor } from "./style";

export const OpenButton = () => {
  return <AddCircleIcon fontSize="large" style={{ color: accentColor }} />;
};

export const CloseButton = () => {
  return <CancelIcon fontSize="large" style={{ color: accentColor }} />;
};
