import axios from "axios";
import { searchResultType, searchType } from "my-types";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import {
  genreHash,
  performingStyleHash,
  regionHash,
  searchDefaultValue,
} from "./constants";
import useWindowDimensions from "./GetWindowSize";
import SearchAccordionCheckbox from "./SearchAccordionCheckbox";
import SearchCheckbox from "./SearchCheckbox";
import SearchDate from "./SearchDate";
import SearchPrice from "./SearchPrice";
import SearchText from "./SearchText";
import { dividerColor, InputButton, topBarHeight } from "./style";

const SearchBlock = styled.div`
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 100%;
  text-align: center;
`;

const SearchForm = styled.form`
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 12px;
  padding-top: 48px;
  gap: 12px 0px;
  background: white;
  overflow-x: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  height: 100%;
  border: solid 1px ${dividerColor};
  @media (min-width: 300px) {
    width: 300px;
  }
`;

function Search(props: {
  setSearchResult: Dispatch<SetStateAction<searchResultType>>;
  setShowSearch: Dispatch<SetStateAction<boolean>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}): JSX.Element {
  const [searchInput, setSearchInput] =
    useState<searchType>(searchDefaultValue);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const searchInputChange = (e: { target: any }) => {
    switch (e.target.name) {
      // ジャンルのステートを変更する
      case "classic":
      case "pops":
      case "jazz":
      case "movie":
      case "game":
      case "anime":
      case "rock":
      case "japanese":
      case "ballet":
      case "genreothers":
        {
          // ブラケットで囲わないとエラーが出た。 参考： https://weblike-curtaincall.ssl-lolipop.jp/blog/?p=1617
          const genreTmp = searchInput.genre;
          genreTmp[e.target.name] = e.target.checked ? true : false;
          setSearchInput((state) => ({
            ...state,
            genre: genreTmp,
          }));
        }
        break;
      // 演奏形態のステートを更新する
      case "solo":
      case "chambermusic":
      case "ensemble":
      case "orchestra":
      case "brassband":
      case "opera":
      case "band":
      case "chorus":
      case "performingothers":
        {
          const performingStyleTmp = searchInput.performingStyle;
          performingStyleTmp[e.target.name] = e.target.checked ? true : false;
          setSearchInput((state) => ({
            ...state,
            performingStyle: performingStyleTmp,
          }));
        }
        break;
      // チケット販売状況のステートを更新する
      case "noticket":
      case "sale":
      case "beforesale":
      case "soldout":
        {
          const purchaseStatusTmp = searchInput.purchaseStatus;
          purchaseStatusTmp[e.target.name] = e.target.checked ? true : false;
          setSearchInput((state) => ({
            ...state,
            purchaseStatus: purchaseStatusTmp,
          }));
        }
        break;
      case "近畿":
      case "関東":
        {
          const regionTmp = searchInput.region;
          regionTmp[e.target.name] = e.target.checked ? true : false;
          setSearchInput((state) => ({
            ...state,
            region: regionTmp,
          }));
          const prefectureTmp = searchInput.prefecture;
          for (const prefectureName of regionHash[e.target.name]) {
            prefectureTmp[prefectureName] = e.target.checked ? true : false;
          }
          setSearchInput((state) => ({
            ...state,
            prefecture: prefectureTmp,
          }));
        }
        break;

      case "茨城県":
      case "栃木県":
      case "群馬県":
      case "埼玉県":
      case "千葉県":
      case "東京都":
      case "神奈川県":
      case "三重県":
      case "滋賀県":
      case "京都府":
      case "大阪府":
      case "兵庫県":
      case "奈良県":
      case "和歌山県":
        {
          const prefectureTmp = searchInput.prefecture;
          prefectureTmp[e.target.name] = e.target.checked ? true : false;
          setSearchInput((state) => ({
            ...state,
            prefecture: prefectureTmp,
          }));
          const regionTmp = searchInput.region;
          // チェックされた都道府県の含まれる地域を取得する
          let targetRegion = "";
          for (const region in regionHash) {
            if (regionHash[region].includes(e.target.name)) {
              targetRegion = region;
              break;
            }
          }
          // その地域の都道府県の state がすべて true ならその地域を true に、
          // 1つでも false ならその地域を false にする。
          const regionBool = regionHash[targetRegion].every((value) => {
            return searchInput.prefecture[value];
          });
          regionTmp[targetRegion] = regionBool;
          setSearchInput((state) => ({
            ...state,
            region: regionTmp,
          }));
        }
        break;
      default:
        setSearchInput((state) => ({
          ...state,
          [e.target.name]: e.target.value,
        }));
    }
  };
  const getInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  useEffect(() => {
    getInstance.get("/").then((res) => {
      props.setSearchResult(res.data);
      props.setIsLoading(false);
    });
  }, []);
  const handleSubmit = (e: any) => {
    if (e.cancelable) {
      // この if 文によって次のエラーを防いでいる。Ignored attempt to cancel a touchmove event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.
      // ソースはこちら https://stackoverflow.com/questions/26478267/touch-move-getting-stuck-ignored-attempt-to-cancel-a-touchmove
      //preventDefault はsubmit でページを更新しないために入れる
      e.preventDefault();
    }
    setIsSearching(true);
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
    });
    instance.post("/search", searchInput).then((res) => {
      if (Object.keys(res.data).length) {
        props.setShowSearch(false);
      } else {
        console.log("検索結果なし");
        // TODO 検索結果がない場合は検索結果がないことを表示する
      }
      setIsSearching(false);
      props.setSearchResult(res.data);
    });
  };

  const height = useWindowDimensions()["height"];

  const FixedSearchButton = () => {
    return (
      <div
        style={{
          width: "100%",
          left: 0,
          top: 12,
          position: "fixed",
          textAlign: "center",
          zIndex: 3,
        }}
      >
        {isSearching ? (
          <InputButton value="検索中" type="submit" />
        ) : (
          <InputButton value="検索" type="submit" />
        )}
      </div>
    );
  };

  return (
    <div style={{ height: height - topBarHeight }}>
      <SearchForm onSubmit={handleSubmit}>
        <FixedSearchButton />
        <div style={{ height: 48 }}></div>
        <SearchDate
          title="検索期間"
          setState={setSearchInput}
          state={searchInput}
        />
        <SearchText
          title="フリーテキスト"
          itemName="free_text"
          changeFunction={searchInputChange}
        />
        <SearchText
          title="タイトル"
          itemName="title"
          changeFunction={searchInputChange}
        />
        <SearchText
          title="曲"
          itemName="music"
          changeFunction={searchInputChange}
        />
        <SearchText
          title="作曲者"
          itemName="composer"
          changeFunction={searchInputChange}
        />
        <SearchText
          title="演奏者"
          itemName="player"
          changeFunction={searchInputChange}
        />
        <SearchAccordionCheckbox
          title="開催地域"
          parentItemName="region"
          childItemName="prefecture"
          checkboxHash={regionHash}
          changeFunction={searchInputChange}
          state={searchInput}
        />
        <SearchCheckbox
          title="ジャンル"
          checkboxHash={genreHash}
          changeFunction={searchInputChange}
        />
        <SearchCheckbox
          title="演奏形態"
          checkboxHash={performingStyleHash}
          changeFunction={searchInputChange}
        />
        <SearchPrice title="料金" changeFunction={searchInputChange} />
        <SearchBlock>
          {isSearching ? (
            <InputButton value="検索中" type="submit" />
          ) : (
            <InputButton value="検索" type="submit" />
          )}
        </SearchBlock>
      </SearchForm>
    </div>
  );
}

export default Search;
