import { hallType } from "my-types";
import React from "react";
import styled from "styled-components";
import useWindowDimensions from "./GetWindowSize";
import MapViewElement from "./MapViewElement";
import { backgroundColor, basePadding, Link, topBarHeight } from "./style";
const HallFrame = styled.div`
  width: 300px;
  height: ${({ theme }) => theme.height - topBarHeight}px;
  background-color: ${backgroundColor};
`;
const HallView = styled.div`
  width: 100%;
  overflow-x: hidden;
  padding: ${basePadding}px;
  box-sizing: border-box;
  height: ${({ theme }) => theme.height - topBarHeight}px;
`;
const HallText = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: ${basePadding}px;
`;
function MapView(props: { hall: hallType }): JSX.Element {
  const height = useWindowDimensions()["height"];
  // 日付もしくは開演時刻でソートする関数
  const concerts = props.hall.concerts;
  const hall = props.hall;
  function concertDateSort(a: string, b: string): number {
    if (concerts[a].concert_date < concerts[b].concert_date) return -1;
    if (concerts[a].concert_date > concerts[b].concert_date) return 1;
    return 0;
  }
  function startTimeSort(a: string, b: string): number {
    if (concerts[a].start_time < concerts[b].start_time) return -1;
    if (concerts[a].start_time > concerts[b].start_time) return 1;
    return 0;
  }

  return (
    <HallFrame theme={{ height: height }}>
      <HallView theme={{ height: height }}>
        <HallText>
          {hall.hall_url ? (
            <Link href={hall.hall_url} target="blank">
              {hall.hall_place}
            </Link>
          ) : (
            <Link target="blank">{hall.hall_place}</Link>
          )}
        </HallText>
        {hall.hall_image && <div>image</div>}
        {(() => {
          const concertViews: Array<JSX.Element> = [];
          Object.keys(concerts)
            .sort(startTimeSort)
            .sort(concertDateSort)
            .forEach((key) => {
              concertViews.push(
                <MapViewElement key={key} {...concerts[key]} />
              );
            });
          return <>{concertViews}</>;
        })()}
      </HallView>
    </HallFrame>
  );
}
export default MapView;
