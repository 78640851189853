import React from "react";
import useWindowDimensions from "./GetWindowSize";
import {
  InfoBlock,
  InfoContents,
  InfoFlame,
  InfoHeader,
  InfoText,
  topBarHeight,
} from "./style";

function SiteInfo(): JSX.Element {
  const width = useWindowDimensions()["width"];
  const height = useWindowDimensions()["height"];

  return (
    <InfoFlame theme={{ height: height - topBarHeight }}>
      <InfoContents
        theme={{
          width: Math.min(800, width) - 24,
          height: height - topBarHeight,
        }}
      >
        <InfoHeader>このサイトについて</InfoHeader>
        <InfoText>
          Concert Map (β版)
          はコンサートホールで開催されるコンサートを便利に検索できるサイトです。
        </InfoText>
        <InfoBlock>
          <InfoHeader>今後の開発予定について</InfoHeader>
          <InfoText>
            今後以下の機能を追加する予定です。
            <ul>
              <li>検索結果をリスト表示する機能</li>
              <li>ユーザーがコンサートを登録できる機能</li>
              <li>検索結果をカレンダー表示する機能</li>
            </ul>
          </InfoText>
        </InfoBlock>
        <InfoBlock>
          <InfoHeader>改善要望・バグ報告について</InfoHeader>
          <InfoText>
            改善してほしい機能がある場合やバグを見つけた場合は、「問い合わせ」のタブからお問い合わせください。
          </InfoText>
        </InfoBlock>
        <InfoBlock>
          <InfoHeader>免責事項</InfoHeader>
          <InfoText>
            このサイトはサイト上の情報に対して明示的、黙示的を問わず、いかなる保証（コンテンツ、文章、画像その他の情報の正確性、完全性、最新性、信頼性、有用性、目的適合性に関する保証、本サイトの使用によって生じる結果の保証を含むがこれらに限られない）もいたしません。
          </InfoText>
          <InfoText>
            また内容に誤りを見つけた場合、「問い合わせ」のタブから訂正依頼していただけますと幸いです。
          </InfoText>
        </InfoBlock>
      </InfoContents>
    </InfoFlame>
  );
}

export default SiteInfo;
