import React from "react";
import { InputTextBox, MediumText, SearchBlock } from "./style";

function SearchText(props: {
  title: string;
  itemName: string;
  changeFunction: any;
}): JSX.Element {
  const title = props.title;
  const itemName = props.itemName;
  const changeFunction = props.changeFunction;

  return (
    <SearchBlock>
      <MediumText>{title}</MediumText>
      <InputTextBox
        type="text"
        name={itemName}
        onChange={changeFunction}
        maxLength={50}
      />
    </SearchBlock>
  );
}

export default SearchText;
